import { HOME_PATH } from '@/constants'
import { useTranslations } from 'next-intl'
import { Button, Flex, Text } from '@edwin-edu/ui/server'
import Link from 'next/link'
import NotFoundIcon from './SVG/NotFound/NotFoundIcon'
import styles from './NotFound.module.scss' // Import the SCSS module

interface NotFoundProps {
  content?: string
  subContent?: string
  backContent?: string
}

export const NotFound = ({
  content,
  subContent,
  backContent,
}: NotFoundProps) => {
  const tc = useTranslations('notFound')

  if (!content) {
    content = tc('content').replace(/<br\s*\/?>/g, '\n')
  }

  if (!subContent) {
    subContent = tc('subContent').replace(/<br\s*\/?>/g, '\n')
  }

  if (!backContent) {
    backContent = tc('homeButton').replace(/<br\s*\/?>/g, '\n')
  }

  return (
    <Flex direction="row" justifyContent="center" className={styles.NotFoundContainer}>
      <div className={styles.NotFoundSVGContainer}>
        <NotFoundIcon />
      </div>

      <Flex direction="column" className={styles.NotFoundContent}>
        {content.split('\n').map((line, index) => (
          <Text key={index} variant="large" className={styles.NotFoundText}>
            {line}
            <br />
          </Text>
        ))}

        <div className={styles.NotFoundSubTextContainer}>
          {subContent.split('\n').map((line, index) => (
            <Text key={index} variant="large" className={styles.NotFoundSubText}>
              {line}
              <br />
            </Text>
          ))}
        </div>

        <div className={styles.NotFoundButton}>
          <Link href={HOME_PATH}>
            <Button data-test="not-found-button" variant="tertiary">
              {backContent}
            </Button>
          </Link>
        </div>
      </Flex>
    </Flex>
  )
}