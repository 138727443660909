'use client'

import { useEffect } from 'react'
import { ApmConfigSchema } from '@/components/Elastic/ElasticInstrumentation'
import { NotFound } from '@/components/NotFound'
import { useElasticApm } from '@/hooks/useElasticApm'
import { useTranslations } from 'next-intl'
import { usePathname, useSearchParams } from 'next/navigation'

import { getUser } from '@/actions/user/getUser'
import { useOpenReplay } from '@/hooks/useOpenReplay'

export default function ErrorPage({ error }: { error: Error & { digest?: string } }) {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const path = pathname + searchParams.toString()

  const tracker = useOpenReplay()
  tracker?.handleError(error, {
    'next.root_layout_error_boundary': 'true',
    'next.route': path,
  })

  const t = useTranslations('common')

  const apmConfig = {
    serverUrl: process.env.NEXT_PUBLIC_ELASTIC_RUM_SERVER_URL,
    serviceName: process.env.NEXT_PUBLIC_ELASTIC_RUM_SERVICE_NAME,
    serviceVersion: process.env.NEXT_PUBLIC_SERVICE_VERSION,
    environment: process.env.NEXT_PUBLIC_ELASTIC_RUM_SERVICE_ENV,
  }

  const parsedApmConfig = ApmConfigSchema.safeParse(apmConfig)

  const apm = useElasticApm(parsedApmConfig.success ? parsedApmConfig.data : undefined)

  useEffect(() => {
    const getTheUser = async () => {
      return await getUser()
    }

    getTheUser().then((user) => {
      apm?.captureError(error)
      apm?.getCurrentTransaction()
      const apmSpan = apm?.startSpan('edwin.error.catchall', 'component')
      apmSpan?.addLabels({ 'edwin.error.catchall.path': path })
      if (user) {
        apm?.setUserContext({
          id: user.userId ?? '',
          username: user.username ?? '',
          email: user.email ?? '',
        })
      }
      apmSpan?.end()
    })
  }, [])

  return <NotFound
    content={t('serviceErrorTitle')}
    subContent={t('serviceErrorDescription')}
    backContent={t('serviceErrorAction')}
  />
}
