import { z } from 'zod'

import { getUser } from '@/actions/user/getUser'

import ElasticClientInstrumentation from './ElasticClientInstrumentation'

export type ApmConfig = z.infer<typeof ApmConfigSchema>
export const ApmConfigSchema = z.object({
  serverUrl: z.string().url(),
  serviceName: z.string(),
  serviceVersion: z.string(),
  environment: z.string(),
})

export type UserData = z.infer<typeof UserDataSchema>
export const UserDataSchema = z.object({
  userId: z.string(),
  username: z.string().optional(),
  email: z.string().optional(),
})

export default async function ElasticInstrumentation() {
  const apmConfig = {
    serverUrl: process.env.ELASTIC_RUM_SERVER_URL,
    serviceName: process.env.ELASTIC_RUM_SERVICE_NAME,
    serviceVersion: process.env.SERVICE_VERSION,
    environment: process.env.ELASTIC_RUM_SERVICE_ENV,
  }

  const parsedApmConfig = ApmConfigSchema.safeParse(apmConfig)
  if (!parsedApmConfig.success) {
    return null
  }

  const userData = await getUser()
  const parsedUserData = UserDataSchema.safeParse(userData)

  return (
    <ElasticClientInstrumentation
      apmConfig={parsedApmConfig.data}
      userData={parsedUserData.success ? parsedUserData.data : undefined} />
  )
}
